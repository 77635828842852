import { DAYSWORKED_LOOKUP_QUERY } from '../queries';

async function fetchDaysWorked(CompanyID, empID) {
    try {

      const response = await fetch('/api/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: DAYSWORKED_LOOKUP_QUERY,
          variables: {
            company: CompanyID,
            empId: empID,
          },
        }),
      });
      if (!response.ok) {
        throw new Error('Total days worked fetch failed');
      }
      const result = await response.json();
      return result.data?.daysworked?.[0]?.value || 0;

    } catch (error) {
      console.error('Error fetching total days worked:', error);
    }
  };
  export default fetchDaysWorked;