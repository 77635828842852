import React from 'react';
import Transition from 'react-transition-group/Transition';

// decorator for components that want to receive transition state
// Usage:
// ```
// const TransitioningComponent = transitionProps(MyComponent)
//
// const Container = (props) =>
//  <TransitioningComponent show={props.showTheComponent} />
// ```
//
// In the TransitioningComponent, use the props entering, entered, exiting and exited to perform the transition
//
// For example with styled-components and using the switchByProp helper:
// ```
// const Overlay = transitionProps(styled.div`
//   position: absolute;
//   top: 0; right: 0; left: 0; bottom: 0;
//   background-color: rgba(0, 0, 0, .4);
//   z-index: 10;
//   transition: all .5s;
//   display: ${switchByProp({entering: 'block', entered: 'block', default: 'none'})};
//   opacity: ${switchByProp({entering: 0, entered: 1, default: 0})};
// `)
// ```
const transitionProps = Component => ({show, timeout = 150, ...props}) => (
  <Transition in={show} timeout={timeout}>
    {status =>
      React.createElement(Component, {
        entering: status === 'entering',
        entered: status === 'entered',
        exiting: status === 'exiting',
        exited: status === 'exited',
        ...props
      })
    }
  </Transition>
);

export default transitionProps;
