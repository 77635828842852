import React, { Component } from 'react';
import Dialog from '../Dialog';
import RequisitionForm from './RequisitionForm';

class RequisitionDialog extends Component {
  state = {
    requisitionId: null,
    submitError: null,
  };

  handleFormSubmitSuccess = (requisitionId) => {
    if (!requisitionId) {
      this.setState({ submitError: 'There was an error retrieving Requisition ID' });
      this.showErrorAlert('There was an error retrieving Requisition ID');
    } else {
      this.setState({ requisitionId, submitError: null });
      this.showAlert(requisitionId);
      this.props.onClose();
    }
  };

  handleFormSubmitError = (error) => {
    this.setState({ submitError: error });
    this.showErrorAlert(error);
  };

  handleFormSubmitError = (error) => {
    this.setState({ errorMessage: error });
  }
  showAlert = (requisitionId) => {
    alert(`Requisition created successfully. Requisition ID: ${requisitionId}`);
  };

  showErrorAlert = (error) => {
    alert(error);
  };

  render() {
    const { heading, title, onClose } = this.props;
    const { submitError } = this.state;

    return (
      <Dialog
        className="req-form modal-dialog"
        title={title}
        onConfirm={onClose}
        showFooter={false}
        onDismiss={onClose}>
        <div className="modal-content p-4">
          {heading && heading.length > 0 && (
            <p className="modal-title">{heading}</p>
          )}
          <div className="form-section">
            <RequisitionForm 
              onFormSubmitSuccess={this.handleFormSubmitSuccess}
              onFormSubmitError={this.handleFormSubmitError}
            />
          </div>
          {submitError && (
            <div className="error-message">
              <p style={{ color: 'red' }}>Error: {submitError}</p>
            </div>
          )}
        </div>
      </Dialog>
    );
  }
}

export default RequisitionDialog;
