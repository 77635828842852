import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Clone from './Clone';
import ApprovalRecall from '../../approval/components/Recall';
import TimesheetRecall from './Recall';
import Submit from './Submit';
import UserSelection from './UserSelection';

import hoc from '../hoc/timesheetControlProps';

import fetchDaysWorked from './DaysWorked';

// Timesheet controls
const TimesheetControlBar = (props) => {
  const {
    currentUser,
    timesheet,
    recallTimesheetApproval,
  } = props;

  const { totals } = timesheet;

  const [totalDaysWorked, setTotalDaysWorked] = useState(null);

  useEffect(() => {

    const fetchTotalDaysWorked = async () => {
      try {
        const daysWorked = await fetchDaysWorked(timesheet.Company, timesheet.EmployeeNum);
        setTotalDaysWorked(daysWorked);
      } catch (error) {
        console.error('Error fetching total days worked:', error);
      }
    };

    fetchTotalDaysWorked();
  }, []); // Empty dependency array means this runs only once after the initial render

  // Determine button states
  const isUnsubmittable = totals.some(x => !x.AllPosted && x.Submitted);
  const isUnapprovable = totals.some(
    x => !x.AllPosted && x.Approved && x.Submitted
  );
  const isSameUser =
    currentUser.Company === timesheet.Company &&
    currentUser.EmployeeNum === timesheet.EmployeeNum;

  return (
    <div className="timesheet-desktop-controls">
      <UserSelection {...props} />
      <Clone {...props} disabled={!props.isCloneable} />
      <div className="timesheet--clone">
        <div className="dropdown btn-group btn-group-tait">
          {totalDaysWorked > 0 && (
            <div id="copy-last-week" className="total-days-worked">
              <span>Total Days Worked: {totalDaysWorked}</span>
            </div>
          )}
        </div>
      </div>
      <Container>
        {props.canApprove ? (
          <ApprovalRecall
            {...props}
            disabled={!(isUnsubmittable || isUnapprovable) || isSameUser}
            isUnsubmittable={isUnsubmittable}
            isUnapprovable={isUnapprovable}
            onRecall={recallTimesheetApproval}
          />
        ) : (
          <TimesheetRecall {...props} disabled={!props.isRecallable} />
        )}
        <Submit {...props} disabled={!props.isSubmittable} />
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

export default hoc(TimesheetControlBar);
