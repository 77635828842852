import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Spinner.css';

/**
 * <Spinner />
 * TODO: may be nice to have an option here to show the spinner only after a certain delay,
 * to avoid blinking effect (when the spinner is shown, but the data loads very fast so the spinner gets hidden right away)
 * Say CWM = start timeout
 *    On Timeout = state = show
 * This could be a HOC (delayRender)
 */
const Spinner = ({className, loadingText}) => (
  <div className={classNames('spinner-container', className)}>
    <div className="spinner-inner">
      {loadingText && <h2>{loadingText}</h2>}
      <div className="spinner-loader">
        <svg className="circular" viewBox="25 25 50 50">
          <circle
            className="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="8"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    </div>
  </div>
);

Spinner.propTypes = {
  loadingText: PropTypes.string
};

export default Spinner;
