// build a switch function that examines a key/value collection based on an object literal,
// and return the first match.
//
// Example usage:
// ```
// const result = switchBy({
//   entering: 'A',
//   entered: 'B',
//   default: 'C'
// })(props)
// ```
//
// If props.entering is false and props.entered is true, then this will return "B"
//
// Optionally the value can be given as a function which will be invoked with the subject as parameter
export default options => subject => {
  const keys = Object.keys(options);
  for (let i = 0; i < keys.length; i++) {
    if (subject[keys[i]]) return getValue(options[keys[i]], subject);
  }
  return getValue(options.default, subject);
};

const getValue = (value, subject) =>
  typeof value === 'function' ? value(subject) : value;
