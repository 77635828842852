import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import Tooltip from 'rc-tooltip';
import {
  REQ_APPROVED_VENDOR_LOOKUP_QUERY,
  REQ_APPROVERS_LOOKUP_QUERY,
  REQ_JOBLIST_LOOKUP_QUERY,
  REQ_ANALYSIS_CODE_LOOKUP_QUERY,
  CREATE_REQUISITION_MUTATION,
  REQ_PART_CLASS_LOOKUP_QUERY,
  REQ_DEFAULT_ADDRESS_LOOKUP_QUERY,
  REQ_CURRENCY_CODE_LOOKUP_QUERY,
  REQ_COUNTRY_LOOKUP_QUERY
} from '../../../timesheet/queries';
import { CURRENT_USER_QUERY } from '../../../auth/queries';

function RequisitionForm({ onFormSubmitSuccess, onFormSubmitError, companyId }) {
  const initialState = {
    requestorID: '',
    companyId: companyId,
    approverID: '',
    CompanyName: '',
    Address1: '',
    Address2: '',
    Address3: '',
    City: '',
    State: '',
    Zip: '',
    Country: '',
    reqDetail: [
      {
        PartNum: '',
        LineDesc: '',
        OrderQty: 1,
        UnitCost: 0,
        Taxable: false,
        Class: '',
        VendorNum: '',
        IUM: 'EA',
        JobNum: '',
        TranType: 'PUR-MTL',
        CommentText: '',
        AnalysisCode: '',
        XOrderQty: 1,
        vendorOptions: [],
        DueDate: '',
        OverrideCurrency: false,
        CurrencyCode: '',
      },
    ],
  };

  const [formData, setFormData] = useState(initialState);
  const [jobOptions, setJobOptions] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [partClasses, setPartClasses] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [analysisCodes, setAnalysisCodes] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [formErrors, setFormErrors] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Fetch current user info
  useEffect(() => {
    async function fetchCurrentUser() {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: CURRENT_USER_QUERY,
          }),
        });
        const data = await response.json();
        const currentUser = data.data.currentUser;
        const formattedName = currentUser.Name.replace(/\s+/g, '.');
        setFormData((prevState) => ({
          ...prevState,
          requestorID: formattedName,
          companyId: currentUser.Company,
        }));
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    }
    fetchCurrentUser();
  }, []);

  // Fetch default address options
  useEffect(() => {
    async function fetchDefaultAddress() {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: REQ_DEFAULT_ADDRESS_LOOKUP_QUERY,
            variables: { company: formData.companyId },
          }),
        });
        const data = await response.json();
        const address = data.data.defAddr[0];

        setFormData((prevData) => ({
          ...prevData,
          CompanyName: address.Company_Name,
          Address1: address.Company_Address1,
          Address2: address.Company_Address2,
          Address3: address.Company_Address3,
          City: address.Company_City,
          State: address.Company_State,
          Zip: address.Company_Zip,
          Country: address.Company_Country,
        }));
        if (countryOptions.length > 0 && address.Company_CountryNum) {
          const matchedCountry = countryOptions.find(
            (country) => country.value === address.Company_CountryNum
          );
          if (matchedCountry) {
            setFormData((prevState) => ({
              ...prevState,
              Country: matchedCountry.value,
            }));
          }
        }
      } catch (error) {
        console.error('Error fetching default address:', error);
      }
    }

    fetchDefaultAddress();
  }, [formData.companyId, countryOptions]);

  useEffect(() => {
    async function fetchCountryOptions() {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: REQ_COUNTRY_LOOKUP_QUERY,
            variables: { company: formData.companyId },
          }),
        });
        const data = await response.json();
        const countries = data.data.reqCountry.map(country => ({
          value: country.Country_CountryNum,
          label: country.Country_Description,
        }));
        setCountryOptions(countries);
      } catch (error) {
        console.error('Error fetching country options:', error);
      }
    }

    fetchCountryOptions();
  }, [formData.companyId]);

  const validateForm = () => {
    if (!formData.CompanyName || !formData.Address1 || !formData.City || !formData.State || !formData.Zip) {
      return 'All address fields are required';
    }

    if (formData.reqDetail.some(detail => !detail.PartNum || !detail.UnitCost || !detail.JobNum)) {
      return 'Each item must have a Part Number, Unit Cost, and Job Number';
    }

    return null;
  };

  // Fetch currency options when the override checkbox is selected
  const fetchCurrencyOptions = async () => {
    try {
      const response = await fetch('/api/graphql', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: REQ_CURRENCY_CODE_LOOKUP_QUERY,
          variables: { company: formData.companyId },
        }),
      });
      const data = await response.json();
      const currencyData = data.data.Req_CurrencyCode.map((currency) => ({
        value: currency.Currency_CurrencyCode,
        label: currency.Currency_CurrencyCode,
      }));
      setCurrencyOptions(currencyData);
    } catch (error) {
      console.error('Error fetching currency options:', error);
    }
  };

  const handleOverrideCurrencyChange = (e, index) => {
    const newReqDetails = [...formData.reqDetail];
    newReqDetails[index].OverrideCurrency = e.target.checked;
    setFormData({ ...formData, reqDetail: newReqDetails });

    if (e.target.checked) {
      fetchCurrencyOptions();
    }
  };

  const handleInputChange = (e, index = null) => {
    const { name, value } = e.target;

    if (['CompanyName', 'Address1', 'Address2', 'Address3', 'City', 'State', 'Zip'].includes(name)) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (index !== null) {
      const newReqDetails = [...formData.reqDetail];

      let fieldValue = value;

      if (name === 'OrderQty' || name === 'VendorNum') {
        fieldValue = parseInt(value, 10);
      } else if (name === 'UnitCost') {
        fieldValue = parseFloat(value);
      }

      if (name === 'DueDate') {
        fieldValue = value;
      }

      newReqDetails[index][name] = fieldValue;

      // Calculate Line Total when OrderQty or UnitCost changes
      if (name === 'OrderQty' || name === 'UnitCost') {
        const orderQty = parseFloat(newReqDetails[index].OrderQty) || 0;
        const unitCost = parseFloat(newReqDetails[index].UnitCost) || 0;
        newReqDetails[index].LineTotal = orderQty * unitCost;
      }

      setFormData({ ...formData, reqDetail: newReqDetails });
    }
  };

  const handleTaxableChange = (e, index) => {
    const newReqDetails = [...formData.reqDetail];
    newReqDetails[index].Taxable = e.target.checked;
    setFormData({ ...formData, reqDetail: newReqDetails });
  };

  const handleSelectChange = (selectedOption, field, index) => {
    const newReqDetails = [...formData.reqDetail];

    if (field === 'VendorNum') {
      const selectedVendor = vendors.find((vendor) => vendor.value === selectedOption.value);
      newReqDetails[index].VendorNum = selectedOption.value;
      if (selectedVendor) {
        newReqDetails[index].CurrencyCode = selectedVendor.CurrencyCode;
      }
    } else if (field === 'JobNum') {
      const selectedJob = jobOptions.find((job) => job.value === selectedOption.value);
      newReqDetails[index].JobNum = selectedOption.value;

      if (selectedJob && selectedJob.ProjectManager) {
        const normalizedProjectManager = selectedJob.ProjectManager.replace(/[\s.]/g, '').toLowerCase();
        const selectedApprover = approvers.find(
          (approver) => approver.value.replace(/[\s.]/g, '').toLowerCase() === normalizedProjectManager
        );

        if (selectedApprover) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            reqDetail: newReqDetails,
            approverID: selectedApprover.value
          }));
        } else {
          console.log("No matching approver found for Project Manager.");
        }
      }
    } else {
      newReqDetails[index][field] = selectedOption.value;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      reqDetail: newReqDetails,
    }));
  };

  const handleCountryChange = (selectedOption) => {
    setFormData(prevState => ({
      ...prevState,
      Country: selectedOption.value,
    }));
  };

  const handleDueDateChange = (e, index) => {
    const newReqDetails = [...formData.reqDetail];
    const selectedDate = e.target.value;

    newReqDetails[index] = {
      ...newReqDetails[index],
      DueDate: selectedDate,
    };

    setFormData((prevData) => ({
      ...prevData,
      reqDetail: newReqDetails,
    }));
  };

  const addNewLine = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      reqDetail: [
        ...prevFormData.reqDetail,
        {
          PartNum: '',
          LineDesc: '',
          OrderQty: 1,
          UnitCost: 0,
          Taxable: false,
          Class: partClasses.length > 0 ? partClasses[0].value : '',
          VendorNum: '',
          JobNum: '',
          IUM: 'EA',
          TranType: 'PUR-MTL',
          CommentText: '',
          AnalysisCode: '',
          XOrderQty: 1,
          vendorOptions: [],
          DueDate: '',
          OverrideCurrency: false,
          CurrencyCode: '',
        },
      ],
    }));
  };

  const removeItem = (indexToRemove) => {
    const newReqDetails = formData.reqDetail.filter((_, index) => index !== indexToRemove);
    setFormData({ ...formData, reqDetail: newReqDetails });
  };

  // Fetch job options
  useEffect(() => {
    async function fetchJobOptions() {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: REQ_JOBLIST_LOOKUP_QUERY,
            variables: { company: formData.companyId },
          }),
        });
        const data = await response.json();
        const jobData = data.data.jobList.map((j) => ({
          value: j.JobNum,
          label: `Project: ${j.ProjectID} - ${j.ProjectDesc} | Job: ${j.JobNum} - ${j.JobDesc}`,
          ProjectManager: j.ProjectManager
        }));
        setJobOptions(jobData);
      } catch (error) {
        console.error('Error fetching job options:', error);
      }
    }

    fetchJobOptions();
  }, [formData.companyId]);

  // Fetch approvers
  useEffect(() => {
    async function fetchApprovers() {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: REQ_APPROVERS_LOOKUP_QUERY,
            variables: { company: formData.companyId },
          }),
        });
        const data = await response.json();
        const approverOptions = data.data.approverList.map((a) => ({
          value: a.value,
          label: a.value,
        }));
        setApprovers(approverOptions);
      } catch (error) {
        console.error('Error fetching approvers:', error);
      }
    }

    fetchApprovers();
  }, [formData.companyId]);

  useEffect(() => {
    async function fetchPartClasses() {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: REQ_PART_CLASS_LOOKUP_QUERY,
            variables: { company: formData.companyId },
          }),
        });
        const data = await response.json();
        const partClassOptions = data.data.partClass.map((p) => ({
          value: p.value,
          label: p.label,
        }));
        setPartClasses(partClassOptions);

        if (partClassOptions.length > 0) {
          setFormData((prevData) => ({
            ...prevData,
            reqDetail: prevData.reqDetail.map((item) => ({
              ...item,
              Class: partClassOptions[0].value,
            })),
          }));
        }
      } catch (error) {
        console.error('Error fetching part classes:', error);
      }
    }

    fetchPartClasses();
  }, [formData.companyId]);

  // Fetch vendors
  useEffect(() => {
    async function fetchVendors() {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: REQ_APPROVED_VENDOR_LOOKUP_QUERY,
            variables: { company: formData.companyId },
          }),
        });
        const data = await response.json();
        const supplierOptions = data.data.approvedVendors.map((s) => ({
          value: s.VendorNum,
          label: s.VendorName,
          CurrencyCode: s.CurrencyCode,
        }));
        setVendors(supplierOptions);
      } catch (error) {
        console.error('Error fetching suppliers:', error);
      }
    }

    fetchVendors();
  }, [formData.companyId]);

  // Fetch analysis codes
  useEffect(() => {
    async function fetchAnalysisCodes() {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: REQ_ANALYSIS_CODE_LOOKUP_QUERY,
            variables: { company: formData.companyId },
          }),
        });
        const data = await response.json();
        const analysisCodeOptions = data.data.acAnalysisCodes.map((a) => ({
          value: a.AnalysisCd_AnalysisCode,
          label: `${a.AnalysisCd_AnalysisCode} - ${a.AnalysisCd_Description}`,
        }));
        setAnalysisCodes(analysisCodeOptions);
      } catch (error) {
        console.error('Error fetching analysis codes:', error);
      }
    }

    fetchAnalysisCodes();
  }, [formData.companyId]);

  const formatDateForSubmission = (date) => {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${month}-${day}-${year}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      setFormErrors(validationError);
      setShowErrorAlert(true);
      return;
    }

    const reqDetailWithFormattedValues = formData.reqDetail.map((item) => {
      const { LineTotal, vendorOptions, DueDate, ...rest } = item;

      return {
        ...rest,
        DueDate: DueDate ? formatDateForSubmission(DueDate) : '',
        OrderQty: parseInt(item.OrderQty, 10),
        UnitCost: parseFloat(item.UnitCost, 10),
        VendorNum: parseInt(item.VendorNum, 10),
        Taxable: item.Taxable ? 'TRUE' : 'FALSE',
        OverrideCurrency: item.OverrideCurrency ? 'TRUE' : 'FALSE',
      };
    });

    try {
      const response = await fetch('/api/graphql', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: CREATE_REQUISITION_MUTATION,
          variables: {
            company: formData.companyId,
            efxLibraryID: 'requisitions',
            efxFunctionID: 'requisition-creation',
            params: {
              requestorID: formData.requestorID,
              approverID: formData.approverID,
              CompanyName: formData.CompanyName,
              Address1: formData.Address1,
              Address2: formData.Address2,
              Address3: formData.Address3,
              City: formData.City,
              State: formData.State,
              Zip: formData.Zip,
              Country: formData.Country,
              reqDetail: {
                reqDetail: reqDetailWithFormattedValues,
              },
            },
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Requisition submission failed');
      }
  
      const jsonResponse = await response.json();
      const requisitionId = jsonResponse.data.createRequisition.output;
  
      // Check if requisitionId starts with "Ice.Common.BusinessObjectException:"
      if (requisitionId.startsWith('Ice.Common.BusinessObjectException:')) {
        throw new Error('An error occurred while submitting the requisition');
      }
  
      if (!requisitionId) {
        throw new Error('There was an error retrieving Requisition ID');
      }
  
      setShowSuccessModal(true);
      setFormData(initialState);
      onFormSubmitSuccess(requisitionId);
    } catch (error) {
      setFormErrors(error.message || 'An error occurred while submitting the requisition');
      setShowErrorAlert(true);
      onFormSubmitError(error.message || 'An error occurred while submitting the requisition');
    }
  };

return (
  <form onSubmit={handleSubmit} className="form-container">
    {/* Address Section */}
    <div className="form-section">
      <h5>Ship To</h5>
      <div className="row">
        <div className="col-md-6">
          <label htmlFor="CompanyName">Company Name</label>
          <input
            type="text"
            className="form-control"
            id="CompanyName"
            name="CompanyName"
            value={formData.CompanyName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="Address1">Address 1</label>
          <input
            type="text"
            className="form-control"
            id="Address1"
            name="Address1"
            value={formData.Address1}
            onChange={handleInputChange}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label htmlFor="Address2">Address 2</label>
          <input
            type="text"
            className="form-control"
            id="Address2"
            name="Address2"
            value={formData.Address2}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="Address3">Address 3</label>
          <input
            type="text"
            className="form-control"
            id="Address3"
            name="Address3"
            value={formData.Address3}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="City">City</label>
          <input
            type="text"
            className="form-control"
            id="City"
            name="City"
            value={formData.City}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="State">State</label>
          <input
            type="text"
            className="form-control"
            id="State"
            name="State"
            value={formData.State}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="Zip">Zip</label>
          <input
            type="text"
            className="form-control"
            id="Zip"
            name="Zip"
            value={formData.Zip}
            onChange={handleInputChange}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label htmlFor="Country">Country</label>
          <ReactSelect
            id="Country"
            name="Country"
            value={countryOptions.find(option => option.value === formData.Country) || null}
            onChange={handleCountryChange}
            options={countryOptions}
            placeholder="Select Country"
            required
          />
        </div>
      </div>
    </div>

    {/* Requisition Items */}
    {formData.reqDetail.map((item, index) => (
      <div key={index} className="form-section requisition-item mb-4">
        <div className="item-header">
          <h5>Item {index + 1}</h5>
          {index > 0 && (
            <button type="button" className="btn btn-danger remove-circle-btn" onClick={() => removeItem(index)}>
              &times;
            </button>
          )}
        </div>

        {/* Part Number and Description */}
        <div className="row">
          <div className="col-md-6">
            <label htmlFor={`PartNum-${index}`}>Part Number</label>
            <input
              type="text"
              className="form-control"
              id={`PartNum-${index}`}
              name="PartNum"
              value={item.PartNum}
              onChange={(e) => handleInputChange(e, index)}
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor={`LineDesc-${index}`}>Description</label>
            <textarea
              type="text"
              className="form-control req-desc"
              id={`LineDesc-${index}`}
              name="LineDesc"
              value={item.LineDesc}
              onChange={(e) => handleInputChange(e, index)}
              rows="4"
              required
            />
          </div>
        </div>

        {/* Due Date */}
        <div className="row">
          <div className="col-md-6">
            <label htmlFor={`DueDate-${index}`}>Due Date</label>
            <input
              type="date"
              className="form-control"
              id={`DueDate-${index}`}
              name="DueDate"
              value={item.DueDate ? item.DueDate : ''}
              onChange={(e) => handleDueDateChange(e, index)}
              required
            />
          </div>
        </div>

        {/* Quantity and Unit Cost */}
        <div className="row">
          <div className="col-md-6">
            <label htmlFor={`OrderQty-${index}`}>Quantity</label>
            <input
              type="number"
              className="form-control"
              id={`OrderQty-${index}`}
              name="OrderQty"
              value={item.OrderQty}
              onChange={(e) => handleInputChange(e, index)}
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor={`UnitCost-${index}`}>Unit Cost</label>
            <div className="d-flex align-items-center">
              <input
                type="number"
                className="form-control"
                id={`UnitCost-${index}`}
                name="UnitCost"
                value={item.UnitCost}
                onChange={(e) => handleInputChange(e, index)}
                required
              />
              <Tooltip
                placement="top"
                overlay="Unit of Measure"
                trigger={['hover']}
              >
                <span className="ml-2 uom-label" style={{ cursor: 'pointer' }}>
                  EA
                </span>
              </Tooltip>
            </div>
          </div>
        </div>

        {/* Line Total */}
        <div className="row">
          <div className="col-md-6">
            <label>Line Total</label>
            <input
              type="text"
              className="form-control"
              value={item.LineTotal ? item.LineTotal.toFixed(2) : 0}
              readOnly
            />
          </div>
        </div>

        {/* Taxable Checkbox */}
        <div className="row">
          <div className="col-md-6">
            <label htmlFor={`Taxable-${index}`}>Taxable</label>
            <input
              type="checkbox"
              id={`Taxable-${index}`}
              name="Taxable"
              checked={item.Taxable}
              onChange={(e) => handleTaxableChange(e, index)}
            />
          </div>
        </div>

        {/* Class and Vendor */}
        <div className="row">
          <div className="col-md-6">
            <label htmlFor={`Class-${index}`}>Class</label>
            <ReactSelect
              id={`Class-${index}`}
              name="Class"
              value={partClasses.find((option) => option.value === item.Class) || null}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'Class', index)}
              options={partClasses}
              placeholder="Select Class"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor={`VendorNum-${index}`}>Vendor</label>
            <ReactSelect
              id={`VendorNum-${index}`}
              name="VendorNum"
              value={item.VendorNum}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'VendorNum', index)}
              options={vendors}
              placeholder="Select Vendor"
              required
            />
          </div>
        </div>

        {/* Currency and Override Checkbox */}
        <div className="row">
          <div className="col-md-6">
            <label htmlFor={`CurrencyCode-${index}`}>Currency</label>
            <ReactSelect
              id={`CurrencyCode-${index}`}
              name="CurrencyCode"
              value={{ value: item.CurrencyCode, label: item.CurrencyCode }}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'CurrencyCode', index)}
              options={currencyOptions}
              placeholder="Select Currency"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor={`OverrideCurrency-${index}`}>Override Currency</label>
            <input
              type="checkbox"
              id={`OverrideCurrency-${index}`}
              name="OverrideCurrency"
              checked={item.OverrideCurrency}
              onChange={(e) => handleOverrideCurrencyChange(e, index)}
            />
          </div>
        </div>

        {/* Project & Job and Analysis Code */}
        <div className="row">
          <div className="col-md-6">
            <label htmlFor={`JobNum-${index}`}>Project & Job</label>
            <ReactSelect
              id={`JobNum-${index}`}
              name="JobNum"
              value={item.JobNum}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'JobNum', index)}
              options={jobOptions}
              placeholder="Select Project & Job"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor={`AnalysisCode-${index}`}>Analysis Code</label>
            <ReactSelect
              id={`AnalysisCode-${index}`}
              name="AnalysisCode"
              value={item.AnalysisCode}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'AnalysisCode', index)}
              options={analysisCodes}
              placeholder="Select Analysis Code"
              required
            />
          </div>
        </div>
      </div>
    ))}

    {/* Add New Line Button */}
    <div className="d-flex justify-content-start add-another-button">
      <button type="button" className="btn btn-success mb-3" onClick={addNewLine}>Add Another Item</button>
    </div>

    {/* Requestor and Approver Section */}
    <div className="form-section">
      <h5>Requester and Approver</h5>
      <div className="row mb-3">
        <div className="col-md-6">
          <label htmlFor="requestorID">Requestor ID</label>
          <input type="text" className="form-control" id="requestorID" name="requestorID" value={formData.requestorID} disabled required />
        </div>
        <div className="col-md-6">
          <label htmlFor="approverID">Approver</label>
          <ReactSelect
            id="approverID"
            name="approverID"
            value={approvers.find((option) => option.value === formData.approverID) || null}
            onChange={(selectedOption) => setFormData({ ...formData, approverID: selectedOption.value })}
            options={approvers}
            placeholder="Select an Approver"
            required
          />
        </div>
      </div>
    </div>
    {/* Submit Button */}
    <div className="d-flex justify-content-end">
      <button type="submit" className="btn btn-primary">Submit Requisition</button>
    </div>
    {/* Success Modal */}
    {showSuccessModal && (
      <div className="modal" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Success</h5>
              <button type="button" className="close" onClick={() => setShowSuccessModal(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Requisition was submitted successfully!</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setShowSuccessModal(false)}>Close</button>
            </div>
          </div>
        </div>
      </div>
    )}
    <style jsx>{`
        .form-section {
          border: 1px solid #d3d3d3;
          padding: 20px;
          margin-bottom: 20px;
          border-radius: 5px;
          background-color: #f9f9f9;
        }

        .item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .btn-success {
          margin-top: 1em;
          background-color: #28a745 !important;
          border-color: #28a745 !important;
          color: #fff !important;
        }

        .btn-primary {
          margin-top: 2em;
          background-color: #007bff !important;
          border-color: #007bff !important;
          color: #fff !important;
        }

        .btn-danger {
          margin-top: 1em;
          background-color: #dc3545 !important;
          border-color: #dc3545 !important;
          color: #fff !important;
        }

        .btn-success:hover {
          background-color: #218838 !important;
          border-color: #1e7e34 !important;
        }

        .btn-primary:hover {
          background-color: #0056b3 !important;
          border-color: #004085 !important;
        }

        .btn-danger:hover {
          background-color: #c82333 !important;
          border-color: #bd2130 !important;
        }

        .remove-circle-btn {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          color: #fff !important;
          background-color: #dc3545 !important;
          border: none;
          cursor: pointer;
        }

        .remove-circle-btn:hover {
          background-color: #c82333 !important;
        }

        .req-desc {
          resize: none !important;
        }
        
        .add-another-button {
          margin-top: 0;
          margin-bottom: 20px;
        }
        
        .uom-label {
          align-content: center;
          font-weight: bold;
          color: #007bff;
          margin-left: 10px;
        }
      `}</style>
  </form>
);
}

export default RequisitionForm;
